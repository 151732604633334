// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-jsx": () => import("/opt/build/repo/src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chainpoint-jsx": () => import("/opt/build/repo/src/pages/chainpoint.jsx" /* webpackChunkName: "component---src-pages-chainpoint-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-proof-jsx": () => import("/opt/build/repo/src/pages/proof.jsx" /* webpackChunkName: "component---src-pages-proof-jsx" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

